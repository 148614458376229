
<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <form id="UpdateExpense" @submit.prevent="UpdateExpense" >
        <CCardHeader>
            <CCol col="6" class="styleHeader">{{ $t('message.updateExpense') }}</CCol>
        </CCardHeader>
           <CCardBody>
            <CRow>
              <CCol sm="6">
                <CInput :label=" $t('message.expenseName')"
                  id="expense_name"
                  v-model="expense.expense_name"
                  class="required"
                  v-on:keypress="isLetter($event)"
                  :placeholder=" $t('message.expenseName') "
                  :invalidFeedback="errors.expense_name"
                  required
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput :label=" $t('message.expensePrice')"
                  id="expense_price"
                  v-model="expense.expense_price"
                  class="required"
                 :placeholder=" $t('message.expensePrice') "
                  :invalidFeedback="errors.expense_price"
                  required
                />
              </CCol>
            </CRow>
             <CRow>
              <CCol sm="6">
                 <CTextarea :label=" $t('message.description')"
                        type='text'
                        id='description'
                        v-model='expense.description'
                    ></CTextarea>
              </CCol>
            </CRow>

          </CCardBody>
            <CCardFooter>
            <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{ $t('message.save') }}</CButton>
              &emsp;
            <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
          </form>
        </CCard>
    </CCol>
</CRow>
</template>

<script>
// import swal from 'sweetalert'
// import $ from 'jquery'
export default {
  name: 'UpdateExpense',
  data () {
    return {
      expense: [],
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}expenses/${this.$route.params.id}/edit`)
      .then((response) => {
        this.expense = response.data.data.expense
        console.log(response.data.data.expense)
      })
  },
  methods: {
    calcTotal ($event) {
      this.expense.total = $event * this.expense.expense_price
    },
    async UpdateExpense () {
      const formData = new FormData()
      formData.append('expense_name', this.expense.expense_name)
      if (this.expense.description !== undefined) {
        formData.append('description', this.expense.description)
      }
      formData.append('expense_price', this.expense.expense_price)

      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, `${this.$hostUrl}expenses/${this.$route.params.id}`, '/expenses')
    },
    goBack () {
      this.productsOpened ? this.$router.go(-1) : this.$router.push({ path: '/expenses' })
    }
  }
}
</script>
<style scoped>
.redborder{
  border-color:red !important
}
</style>
